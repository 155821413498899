import { DataSourceColumn } from '@/models/base/data/DataSourceColumn';
import { ClassConstructor } from 'class-transformer';

export class DataSource<T> {
  private _queryUrl?: string

  public baseUrl!: string

  get queryUrl(): string {
    // TODO разобраться почему не работает глобальное правило
    /* eslint eqeqeq: "off" */
    if (this._queryUrl == null || this._queryUrl == '') {
      return this.baseUrl;
    }

    return this._queryUrl;
  }

  set queryUrl(value: string) {
    this._queryUrl = value;
  }

  public loading = false

  public page = 1

  public itemsPerPage = 10

  public total: number | null = null

  public propertyResponseName = ''

  public argumentType!: ClassConstructor<T>

  public columns: Array<DataSourceColumn<T>> = new Array<DataSourceColumn<T>>()

  public items: Array<T> = new Array<T>()

  public refresh: (() => void) = () => {
    console.error('refresh not implemented');
  }

  constructor(init?: Partial<DataSource<T>>) {
    Object.assign(this, init);
  }
}

/*
  По сути должен быть класс дата соурс для таблицы
  в котором лежат ендпоинт и флаг загрузки
    url: '/api/v1/locomotives',
    loading: false,
    page: 1,
    itemsPerPage: 10,
  Потом должна быть коллекция column-ов
    sortable: true,
    filterable: true,
    groupable: true,

    divider?: boolean, Надо выстаить на всю таблицу дает полоски

    propertyLabel: 'Название',
    propertyName: 'name',
    width: 100px,

    //Как то отметить filterOpen: false, чтобы снаружи не было, но внутри была возможность управлять
    //Наверное чисто оставить внутри компонента
    dataFilter: {
      operator: '==',
      value: 'ВЛ80С',
    },

    ---------------------------------------
    Наверное весь фильтр надо засунуть в один компонент это поможет избавиться от filterOpen: false
    Убрать компонент rd-data-table-text-filter
    <v-menu
        v-model="header.filterOpen"
        :key="header.value"
        :close-on-content-click="false"
        :nudge-width="300"
        :max-width="300"
        :min-width="300"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <span>
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                filter_alt
              </v-icon>
            </v-btn>
            {{ formatHeader(header) }}
          </span>
        </template>
        <v-card>
          <rd-data-table-text-filter :column-name="header.value" :value="header" />

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              text
              @click="header.filterOpen = false"
            >
              Применить
            </v-btn>
            <v-btn
              text
              @click="header.filterOpen = false"
            >
              Очистить
            </v-btn>
          </v-card-actions>
        </v-card>

  headers: [{
  text: 'Айди',
  value: 'id',
  sortable: false,
  filterOpen: false,
  dataTableFilter: new DataTableFilter<string>({
    propertyName: 'id',
    value: 'ВЛ80С',
    operator: '=='
  }),
 */
