export enum FilterOperator {
  Equals = '==',
  NotEquals = '!=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqualTo = '>=',
  LessThanOrEqualTo = '<=',
  Contains = '@=',
  StartsWith = '_=',
  DoesNotContains = '!@=',
  DoesNotStartsWith = '!_=',
  CaseInsensitiveStringContains = '@=*',
  CaseInsensitiveStringStartsWith = '_=*',
  CaseInsensitiveStringEquals = '==*',
  CaseInsensitiveStringNotEquals = '!=*',
  CaseInsensitiveStringDoesNotContains = '!@=*',
  CaseInsensitiveStringDoesNotStartsWith = '!_=*',
}
