


















































import {
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
} from '@vue/composition-api';
import { plainToClass } from 'class-transformer';
import { RdDataTableHeader } from '@/models/base/data/RdDataTableHeader';
import { DataSource } from '@/models/base/data/DataSource';
import { DataSourceColumn } from '@/models/base/data/DataSourceColumn';
import { DataOptions } from 'vuetify';
import RdDataTableFilter from '@/components/base/data/RdDataTableFilter.vue';
import { FilterQueryBuilder } from '@/services/data/FilterQueryBuilder';
import { ObjectUtils } from '@/helpers/ObjectUtils';
import { TableDataWrapper } from '@/models/base/data/TableDataWrapper';
import { SimpleModel } from '@/models/base/SimpleModel';
import { HttpService } from '@/services/network/HttpService';
import { Container } from '@/Container';

export default defineComponent({
  name: 'RdDataTable',
  components: { RdDataTableFilter },
  props: {
    dataSource: {
      type: Object as PropType<DataSource<any>>,
      required: true,
    },
    showExpand: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleExpand: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['expanded'],
  setup: (props, ctx) => {
    const httpService = Container.resolve<HttpService>("HttpService");

    const formatHeader = (header: RdDataTableHeader) => {
      return header.text;
    };

    const getHeaderSlotName = (column: DataSourceColumn<any>) => {
      return `header.${column.propertyName}`;
    };

    const getItemSlotName = (column: DataSourceColumn<any>) => {
      return `item.${column.propertyName}`;
    };

    const isSimpleModelLike = (item: any) => {
      if (item !== Object(item)) {
        return false;
      }

      return 'id' in item && 'name' in item;
    };

    const formatSimpleModelLike = (item: SimpleModel) => {
      return item.name;
    };

    const menu = ref(false);

    const reactiveTableInfo = reactive({
      tableInfo: {
        page: props.dataSource.page,
        itemsPerPage: props.dataSource.itemsPerPage,
        total: props.dataSource.total,
      },
    });

    const getHeaders = (columns: Array<DataSourceColumn<any>>) => {
      return columns.map((c) => {
        return {
          text: c.propertyLabel,
          value: c.propertyName,
          width: c.width,
          sortable: c.sortable,
          filterable: c.filterable,
          groupable: c.groupable,
        };
      });
    };

    const reactiveDataSource = reactive({
      dataSource: props.dataSource as (DataSource<any>),
    });

    const loadData = () => {
      const { dataSource } = reactiveDataSource;
      dataSource.loading = true;
      httpService.get<any>(dataSource.queryUrl)
        .then(async (result) => {
          const key = dataSource.propertyResponseName;
          const tableData = ObjectUtils.findValue(result.data, key) as TableDataWrapper<any>;
          const items = plainToClass(dataSource.argumentType, tableData.data);
          dataSource.items = items;
          dataSource.total = tableData.total;
          dataSource.page = tableData.page;
          dataSource.itemsPerPage = tableData.itemsPerPage;
          dataSource.loading = false;
        })
        .catch(() => {
          dataSource.loading = false;
        });
    };

    reactiveDataSource.dataSource.refresh = loadData;

    function buildQueryUrlAndLoadData() {
      reactiveDataSource.dataSource.queryUrl = new FilterQueryBuilder()
        .buildFromDataSource(reactiveDataSource.dataSource);
      loadData();
    }

    watch([
      () => reactiveDataSource.dataSource.columns,
    ],
    (current, old) => {
      console.log('columns changed');
      reactiveDataSource.dataSource.page = 1;
    }, {
      deep: true,
    });

    watch([
      () => reactiveDataSource.dataSource.itemsPerPage,
      () => reactiveDataSource.dataSource.page,
    ],
    (current, old) => {
      console.log('pages changed');
      buildQueryUrlAndLoadData();
    });

    buildQueryUrlAndLoadData();

    const optionsUpdated = (options: DataOptions) => {
      console.log('options updated');
      reactiveDataSource.dataSource.page = options.page;
      reactiveDataSource.dataSource.itemsPerPage = options.itemsPerPage;
    };

    const rowExpanded = (data: any) => {
      ctx.emit('expanded', data);
    };

    return {
      menu,
      reactiveDataSource,
      reactiveTableInfo,
      isSimpleModelLike,
      formatSimpleModelLike,
      formatHeader,
      getHeaderSlotName,
      getItemSlotName,
      getHeaders,
      optionsUpdated,
      rowExpanded,
    };
  },
});
