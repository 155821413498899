





















































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { DataSourceColumn } from '@/models/base/data/DataSourceColumn';
import { FilterOperator } from '@/models/base/data/FilterOperator';
import { DataFilter } from '@/models/base/data/DataFilter';

export default defineComponent({
  name: 'RdDataTableFilter',
  props: {
    column: {
      type: Object as PropType<DataSourceColumn<any>>,
      required: true,
    },
  },
  setup: (props, ctx) => {
    const filterIsOpen = ref(false);

    const filters = ref([
      FilterOperator.CaseInsensitiveStringEquals,
      FilterOperator.CaseInsensitiveStringNotEquals,
      FilterOperator.CaseInsensitiveStringContains,
      FilterOperator.CaseInsensitiveStringDoesNotContains,
      FilterOperator.CaseInsensitiveStringStartsWith,
    ]);

    const filterOperator = ref(null as FilterOperator | null);
    const filterValue = ref('');

    const filterLabel = (operator: FilterOperator) => {
      if (operator === FilterOperator.CaseInsensitiveStringEquals) {
        return 'Соответствует';
      }

      if (operator === FilterOperator.CaseInsensitiveStringNotEquals) {
        return 'Не соответствует';
      }

      if (operator === FilterOperator.CaseInsensitiveStringContains) {
        return 'Содержит';
      }

      if (operator === FilterOperator.CaseInsensitiveStringDoesNotContains) {
        return 'Не содержит';
      }

      if (operator === FilterOperator.CaseInsensitiveStringStartsWith) {
        return 'Начинается с';
      }

      return operator.toString();
    };

    const applyFilter = () => {
      props.column.dataFilter = new DataFilter({
        operator: filterOperator.value!,
        value: filterValue.value,
      });
      filterIsOpen.value = false;
    };

    const cancelFilter = () => {
      filterOperator.value = null;
      filterValue.value = '';
      props.column.dataFilter = new DataFilter({
        operator: filterOperator.value!,
        value: filterValue.value,
      });
      filterIsOpen.value = false;
    };

    return {
      filterIsOpen,
      filters,
      filterOperator,
      filterValue,
      header: props.column as DataSourceColumn<any>,
      filterLabel,
      applyFilter,
      cancelFilter,
    };
  },
});
