import { DataFilter } from '@/models/base/data/DataFilter';
import { DataSorter } from '@/models/base/data/DataSorter';

export class DataSourceColumn<T> {
  public sortable = true;

  public filterable = true;

  public groupable = false;

  // public divider: boolean = false // Надо выстаить на всю таблицу дает полоски

  public propertyLabel!: string;

  public propertyName!: string;

  public width: number | string | null = null;

  public dataFilter: DataFilter | null = null;

  public dataSorter: DataSorter | null = null;

  public formatter: ((data: T) => string) | null = null;

  constructor(init?: Partial<DataSourceColumn<T>>) {
    Object.assign(this, init);
  }
}
