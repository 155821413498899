import { DataSource } from '@/models/base/data/DataSource';

export class FilterQueryBuilder {
  public buildFromDataSource(dataSource: DataSource<any>): string {
    let url = `${dataSource.baseUrl}?page=${dataSource.page}&pageSize=${dataSource.itemsPerPage}&`;

    let filterQuery = 'filters=';
    let hasFilter = false;

    dataSource.columns.forEach((column) => {
      if (column.dataFilter == null) {
        return;
      }
      hasFilter = true;
      filterQuery += `${column.propertyName}${column.dataFilter.operator}${column.dataFilter.value},`;
    });

    if (hasFilter) {
      url = `${url}${filterQuery}`;
    }

    return url;
  }
}
