import { FilterOperator } from '@/models/base/data/FilterOperator';

export class DataFilter {
  public operator!: FilterOperator;

  public value!: number | string | boolean;

  constructor(init?: Partial<DataFilter>) {
    Object.assign(this, init);
  }
}
